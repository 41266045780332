












































































































































































import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator';
import { ReportData, ReportModel } from '@/models/report/ReportModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import ReportApi from '@/api/report-design/ReportApi';
import AppApi from '@/api/application/AppApi';
import FileApi from '@/api/system/FileApi';
import EleUpload from '../form-design/elements/ele-upload.vue';

@Component({
  name: 'ReportConfig',
  components: { EleUpload }
})
export default class ReportConfig extends Vue {
  @Ref() readonly form: { validate: () => any };
  // 应用id
  @Prop({ default: '' }) applicationId!: string;

  // 编辑时，打印模板id
  @Prop({ default: '' }) reportId!: string;
  @Prop({ default: '' }) releaseId!: string;
  @Prop({ default: '' }) type: string;

  @Prop({ default: false })
  visible!: boolean; // 流程设置弹框 的显示隐藏

  reportData: ReportModel = SystemUtil.cloneDeep(ReportData); // 流程模板配置
  reportFileIds: any = []; // 上传组件对应的文件ids
  reportLinkWords: any[] = []; // 模板关联对象的字段
  reportFileWords: any = []; // 模板文件中的文本域
  reportFileAccepts: any = ['.doc', '.docx'];
  reportUsedOptions: any[] = []; // 模板用途映射项
  dialogVisiable: boolean = false; // 是否显示页面
  dialogVisiableConst: boolean = true; // 是否显示页面常量
  OldMappings: any = null; // 是否显示页面常量
  errorFlag: boolean = false;
  formRules: Record<string, any> = {
    linkType: {
      required: true,
      message: this.$t('lang_please_enter_purpose'),
      trigger: 'blur'
    },
    name: {
      required: true,
      message: this.$t('lang_please_enter_template_name'),
      trigger: 'blur'
    },
    applicationLinkId: {
      required: true,
      message: this.$t('lang_please_select_template_obj'),
      trigger: 'blur'
    },
    fileId: {
      required: true,
      message: this.$t('lang_please_upload_file'),
      trigger: 'change'
    }
  };
  created(): void {
    this.initData();
  }
  //createdFlg是否是第一次调用
  initData(firstFlg?: string) {
    // 如果是编辑，获取详情
    if (this.reportId) {
      this.getReportDetail(this.reportId, firstFlg);
    } else {
      this.reportData = SystemUtil.cloneDeep(ReportData);
      this.reportData.applicationId = this.applicationId;
      this.reportFileIds = [];
    }
  }

  /**
   * 获取配置详情
   * @参数 firstFlg:是否第一次执行
   */
  getReportDetail(reportId: string, firstFlg?: any) {
    ReportApi.getReportDetail({ applicationPrintId: reportId }).then((res: any) => {
      if (res.code == '1' && res.data) {
        this.reportData = res.data;
        this.reportFileWords = res.data.configuration.mappingKeys;
        if (res.data.configuration && res.data.configuration.templateFileId) {
          this.reportFileIds = [res.data.configuration.templateFileId];
          // this.getTemplateWords(res.data.configuration.templateFileId);
        }
        this.changeReportType(this.reportData.linkType);
        this.changeReportLink(this.reportData.applicationLinkId, firstFlg);
      } else {
        this.reportData = SystemUtil.cloneDeep(ReportData);
      }
    });
  }

  /**
   * 改变配置用途（1：表单，2：流程）时，获取配置表单列表或流程列表
   */
  changeReportType(val: any) {
    let params = {
      applicationId: this.applicationId,
      linkType: val
    };
    AppApi.getApplicationLinkDataList(params).then((res: any) => {
      if (res.code == '1') {
        this.reportUsedOptions = res.data;
      }
    });
  }

  /**
   * 改变配置关联对象
   * @参数 firstFlg:是否第一次执行
   */
  changeReportLink(val: string, firstFlg?: string) {
    let params = {
      applicationLinkId: val
    };
    ReportApi.getReportLinkFields(params).then((res: any) => {
      if (res.code == '1') {
        this.reportLinkWords = res.data;
        if (firstFlg == 'first') {
          const mappings = this.reportData.configuration.mappings;

          if (!this.reportLinkWords || this.reportLinkWords.length == 0) {
            mappings.map((item) => {
              item.dataKey = null;
            });
          } else if (this.reportLinkWords && this.reportLinkWords.length) {
            // mappings.map(item => {
            //   if (item.dataKey && !this.hasDataKey(this.reportLinkWords, item.dataKey)) {
            //     item.dataKey = null;
            //   }
            // });
          }
          this.reportData.configuration.mappings = mappings;
        }
      }
    });
  }
  hasDataKey(arr: string | any[], dataKey: any): boolean {
    let flg = false;
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      if (item.code == dataKey) {
        flg = true;
        break;
      } else if (item.children && item.children.length) {
        flg = this.hasDataKey(item.children, dataKey);
        return flg;
      }
    }
    return flg;
  }

  /**
   * 配置关联对象树的格式化
   */
  normalizerHandler(node: {
    code: any;
    text: any;
    children: string | any[];
    disabled: any;
  }) {
    let tmp: any = {
      id: node.code,
      label: node.text
    };
    if (node.children && node.children.length) {
      tmp.children = node.children;
    } else {
      tmp.children = undefined;
      tmp.isDisabled = node.disabled;
    }
    return tmp;
  }

  /**
   * 上传模板文件成功后
   */
  uploadReportTemplate(fileList: any[]) {
    let fileNames: string[] = [];
    let fileIds: string[] = [];
    fileList.forEach((item: { fileName: string; id: string }) => {
      fileNames.push(item.fileName);
      fileIds.push(item.id);
    });
    if (fileIds && fileIds.length) {
      this.reportFileIds = fileIds;
      this.reportData.configuration.templateFileId = fileIds[0];
      this.errorFlag = false;
      this.getTemplateWords(fileIds[0], true);
    } else {
      this.reportFileIds = [];
      this.reportData.configuration.templateFileId = '';
      this.errorFlag = true;
    }
  }

  /**
   * 获取模板中的域
   */
  getTemplateWords(fileId: string, changeFile?: boolean) {
    FileApi.getFileFields({ templateFileId: fileId }).then((res: any) => {
      if (res.code == '1' && res.data) {
        this.reportFileWords = res.data;
        if (
          this.reportData.configuration &&
          this.reportData.configuration.templateFileId
        ) {
          this.reportData.configuration.mappingKeys = res.data;
          if (!this.OldMappings) {
            const OldMappings = this.reportData.configuration.mappings || [];
            this.OldMappings = OldMappings;
          }
          if (!this.OldMappings || this.OldMappings?.length == 0) {
            this.reportData.configuration.mappings = res.data.map(
              (item: { key: any }) => {
                return { printKey: item.key, dataKey: null };
              }
            );
          } else if (changeFile && this.OldMappings && this.OldMappings.length) {
            let oldMappingsMap = {};
            this.OldMappings.map((item: { dataKey: any; printKey: string | number }) => {
              if (item.dataKey) {
                oldMappingsMap[item.printKey] = item.dataKey;
              }
            });
            this.reportData.configuration.mappings = res.data.map(
              (item: { key: string | number }) => {
                return {
                  printKey: item.key,
                  dataKey: oldMappingsMap[item.key] ? oldMappingsMap[item.key] : null
                };
              }
            );
          }
        }
      } else if (changeFile) {
        this.reportFileWords = [];
        this.reportData.configuration.mappingKeys = [];
        this.reportData.configuration.mappings = [];
      }
    });
  }

  /**
   * 保存配置
   */
  saveConfig() {
    if (this.type == 'readonly') {
      this.$emit('success', {});
    } else {
      if (this.reportData.configuration.templateFileId == '') {
        this.errorFlag = true;
        return false;
      } else {
        this.errorFlag = false;
      }
      Promise.all([this.form.validate()]).then(() => {
        ReportApi.saveReport(this.reportData).then((res: any) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            this.dialogVisiable = false;
            this.$emit('success', {});
          } else {
            this.$message.error(res.message);
          }
        });
      });
    }
  }
  /**
   * 发布
   */
  releaseConfig() {
    Promise.all([this.form.validate()]).then(() => {
      let flag = this.reportData.configuration.mappings.some((item: any) => {
        return !item.dataKey;
      });
      if (this.reportData.configuration.templateFileId == '') {
        this.errorFlag = true;
        return false;
      } else {
        this.errorFlag = false;
      }
      if (this.reportData.configuration.mappings.length == 0 || flag) {
        this.$confirm(
          `映射参数未配置完整，${this.$t('lang_are_you_sure_to_release_it') as any}`,
          this.$t('lang_tips') as any,
          {
            confirmButtonText: this.$t('lang_determine_') as any,
            cancelButtonText: this.$t('lang_cancel_') as any,
            type: 'warning'
          }
        ).then(() => {
          AppApi.publishAppLink(this.releaseId).then((res: Record<string, any>) => {
            if (res.code == '1') {
              this.handleColseDialog();
              this.$emit('success', {});
            } else {
              this.$message.error(res.message);
            }
          });
        });
      } else {
        AppApi.publishAppLink(this.releaseId).then((res: Record<string, any>) => {
          if (res.code == '1') {
            this.handleColseDialog();
            this.$emit('success', {});
          } else {
            this.$message.error(res.message);
          }
        });
      }
    });
  }
  /**
   * 关闭弹窗
   */
  handleColseDialog() {
    this.reportFileIds = [];
    this.$emit('update:visible', false);
  }

  @Watch('visible')
  visibleWatcher(val: boolean) {
    // 弹窗显示
    if (val) {
      // 获取表单列表
      this.dialogVisiable = val;
      this.OldMappings = null;
      this.initData('first');
    } else {
      this.reportUsedOptions = [];
      this.dialogVisiable = val;
    }
  }
}
