export interface ReportConfiguration {
  mappingKeys: any[];
  mappings: any[];
  templateFileId: string;
}

export interface ReportModel {
  applicationId: string;
  applicationLinkId: string;
  configuration: ReportConfiguration;
  description: string;
  id: string;
  name: string;
  sort: 0;
  [prop: string]: any;
}

export const ReportData: ReportModel = {
  id: '',
  name: '',
  sort: 0,
  tenantId: '',
  description: '',
  applicationId: '',
  applicationLinkId: '',
  configuration: {
    mappingKeys: [],
    mappings: [],
    templateFileId: ''
  }
};
