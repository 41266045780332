var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("lang_template_settings"),
        visible: _vm.dialogVisiable,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "custom-class": "report-dialog",
        width: "60%",
        top: "5vh",
      },
      on: { close: _vm.handleColseDialog },
    },
    [
      _vm.dialogVisiable
        ? _c(
            "el-form",
            {
              ref: "form",
              staticClass: "flow-config-form",
              attrs: {
                "label-width": "100px",
                model: _vm.reportData,
                rules: _vm.formRules,
              },
            },
            [
              _c("el-divider", { staticClass: "cus-divider" }, [
                _vm._v(_vm._s(_vm.$t("lang_bsic_information"))),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_purpose_of_template"),
                            prop: "linkType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.type == "readonly" },
                              on: { change: _vm.changeReportType },
                              model: {
                                value: _vm.reportData.linkType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.reportData, "linkType", $$v)
                                },
                                expression: "reportData.linkType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v(_vm._s(_vm.$t("lang_form"))),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("lang_technological_process"))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_template_name"),
                            prop: "name",
                          },
                        },
                        [
                          _vm.type == "readonly"
                            ? _c("el-input", {
                                attrs: { rows: 4, disabled: "" },
                                model: {
                                  value: _vm.reportData.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reportData, "name", $$v)
                                  },
                                  expression: "reportData.name",
                                },
                              })
                            : _c("lang-input", {
                                staticClass: "input",
                                attrs: {
                                  placeholder: _vm.$t("lang_please_enter"),
                                  disabled: _vm.type == "readonly",
                                },
                                model: {
                                  value: _vm.reportData.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reportData, "name", $$v)
                                  },
                                  expression: "reportData.name",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_template_object"),
                            prop: "applicationLinkId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "no-data-text": _vm.$t("lang_no_data"),
                                placeholder: _vm.$t("lang_please_select"),
                                disabled: _vm.type == "readonly",
                              },
                              on: { change: _vm.changeReportLink },
                              model: {
                                value: _vm.reportData.applicationLinkId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.reportData,
                                    "applicationLinkId",
                                    $$v
                                  )
                                },
                                expression: "reportData.applicationLinkId",
                              },
                            },
                            _vm._l(_vm.reportUsedOptions, function (option) {
                              return _c("el-option", {
                                key: option.id,
                                attrs: {
                                  label: option.link.displayName,
                                  value: option.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("lang_template_description") },
                        },
                        [
                          _vm.type == "readonly"
                            ? _c("el-input", {
                                attrs: { rows: 4, disabled: "" },
                                model: {
                                  value: _vm.reportData.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reportData, "description", $$v)
                                  },
                                  expression: "reportData.description",
                                },
                              })
                            : _c("lang-input", {
                                attrs: { type: "textarea", rows: 4 },
                                model: {
                                  value: _vm.reportData.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reportData, "description", $$v)
                                  },
                                  expression: "reportData.description",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider", { staticClass: "cus-divider" }, [
                _vm._v(_vm._s(_vm.$t("lang_template_configuration"))),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_template_file") } },
                        [
                          _c("ele-upload", {
                            staticClass: "upload-demo",
                            staticStyle: { width: "100%" },
                            attrs: {
                              accept: _vm.reportFileAccepts,
                              limit: 1,
                              disabled: _vm.type == "readonly",
                            },
                            on: { success: _vm.uploadReportTemplate },
                            model: {
                              value: _vm.reportFileIds,
                              callback: function ($$v) {
                                _vm.reportFileIds = $$v
                              },
                              expression: "reportFileIds",
                            },
                          }),
                          _vm.errorFlag
                            ? _c(
                                "span",
                                {
                                  staticClass: "error",
                                  staticStyle: { color: "red" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("lang_please_upload_file")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "template-mapping-wrapper" }, [
                _c("div", { staticClass: "template-mapping-header" }, [
                  _c("div", { staticClass: "template-mapping-column" }, [
                    _vm._v(_vm._s(_vm.$t("lang_template_file_ield"))),
                  ]),
                  _c("div", { staticClass: "template-mapping-column" }, [
                    _vm._v(_vm._s(_vm.$t("lang_mapping_parameters"))),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "template-mapping-content" },
                  _vm._l(
                    _vm.reportData.configuration.mappings,
                    function (mappingItem, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "template-mapping-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "template-mapping-fields" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    readonly: "",
                                    filterable: "",
                                    disabled: _vm.type == "readonly",
                                  },
                                  model: {
                                    value: mappingItem.printKey,
                                    callback: function ($$v) {
                                      _vm.$set(mappingItem, "printKey", $$v)
                                    },
                                    expression: "mappingItem.printKey",
                                  },
                                },
                                _vm._l(
                                  _vm.reportFileWords,
                                  function (word, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: word.key,
                                        value: word.key + index,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "template-mapping-fields" },
                            [
                              _c("treeselect", {
                                staticClass: "cus-treeselect",
                                attrs: {
                                  appendToBody: "",
                                  zIndex: "9999",
                                  disabled: _vm.type == "readonly",
                                  options: _vm.reportLinkWords,
                                  normalizer: _vm.normalizerHandler,
                                  noOptionsText: _vm.$t("lang_no_data"),
                                  noResultsText: _vm.$t(
                                    "lang_no_search_results"
                                  ),
                                  placeholder: _vm.$t("lang_please_select"),
                                },
                                model: {
                                  value: mappingItem.dataKey,
                                  callback: function ($$v) {
                                    _vm.$set(mappingItem, "dataKey", $$v)
                                  },
                                  expression: "mappingItem.dataKey",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.handleColseDialog } }, [
            _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
          ]),
          _vm.type != "readonly"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveConfig } },
                [_vm._v(_vm._s(_vm.$t("lang_save_")))]
              )
            : _vm._e(),
          _vm.reportData.status == 0 && _vm.type != "readonly"
            ? _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: { click: _vm.releaseConfig },
                },
                [_vm._v(_vm._s(_vm.$t("lang_release")))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }